import React from "react";
import { Button, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useAuth from "../../Hooks/firebase/useAuth";
import axios from "axios";
import { hosts } from "../../Config/hosts";

const DashboardCmp = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({
    h1: "",
    title: "",
    intent: "",
    words: 1000,
  });

  const handleH1Gen = () => {
    setIsLoading(true);
    axios.post(`${hosts.api}/gen/h1`, { ...data }).then((res) => {
      setData({ ...data, h1: res.data.result });
      setIsLoading(false);
    });
  };

  const handleArticle = () => {
    setIsLoading(true);
    axios.post(`${hosts.api}/gen/article`, { ...data }).then((res) => {
      console.log({ res, result: res.data.result });
      setData({ ...data, content: res.data.result });
      setIsLoading(false);
    });
  };

  console.log(data);

  return (
    <>
      <Box
        sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            "&:hover": {},
          }}
          onClick={() => auth.signOut()}
        >
          <Typography variant="caption" sx={{ cursor: "pointer" }}>
            Signout
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3">Article Settings</Typography>
        </Box>
        <Box>
          <input
            type="text"
            placeholder="title"
            value={data?.title}
            onChange={(e) => {
              setData((prev: any) => ({ ...prev, title: e.target.value }));
            }}
          />
          <br />
          <br />
          <input
            type="text"
            placeholder="user intent"
            value={data?.intent}
            onChange={(e) => {
              setData((prev: any) => ({ ...prev, intent: e.target.value }));
            }}
          />{" "}
          <br />
          <br />
          <input
            type="text"
            placeholder="words"
            value={data?.words}
            onChange={(e) => {
              setData((prev: any) => ({ ...prev, words: e.target.value }));
            }}
          />
          <br />
          <br />
          <textarea
            style={{ height: "200px" }}
            value={data?.h1}
            onChange={(e) => {
              setData((prev: any) => ({ ...prev, h1: e.target.value }));
            }}
          >
            list of h1
          </textarea>
        </Box>
        {isLoading === false && (
          <Box>
            <Button
              variant="contained"
              sx={{ margin: "10px" }}
              onClick={handleH1Gen}
            >
              Generate H1
            </Button>
            <Button
              variant="contained"
              sx={{ margin: "10px" }}
              onClick={handleArticle}
            >
              Generate Text
            </Button>
          </Box>
        )}
        {isLoading === true && <Box>Loading</Box>}
        <Box sx={{ width: "100%" }}>
          <div dangerouslySetInnerHTML={{ __html: data.content || "" }} />
        </Box>
      </Box>
      {/* <Drawer
        variant="persistent"
        anchor={"right"}
        open={true}
        onClose={() => null}
      >
        <Box sx={{ width: "50vw" }}>test</Box>
      </Drawer> */}
    </>
  );
};

export default DashboardCmp;
