import React from "react";
import AuthService from "./Services/AuthService";

const Services: React.FC<any> = ({ children }) => {
  return (
    <>
      <AuthService />
      {children}
    </>
  );
};

export default Services;
