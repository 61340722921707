// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB8GdNpSJCxQwgawlyMHHFCdIipjkksW7o",
  authDomain: "threecatagency.firebaseapp.com",
  projectId: "threecatagency",
  storageBucket: "threecatagency.appspot.com",
  messagingSenderId: "406262702304",
  appId: "1:406262702304:web:7935b2e1738d326ab93085",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const initFirebase = () => {};
