export type IUser = { [key: string]: any }

export type IUserContextState = {
  user: IUserUnionStates
  signInUser: (newUser: IUser) => boolean
  signOutUser: () => boolean
  setLoadingState: () => boolean
}

export enum IUserStates {
  SignedIn = 0,
  SignedOut = 1,
  Loading = 2,
  Error = 3,
}
export type IAuthenticatedUser = {
  state: IUserStates.SignedIn
  data: IUser
}

type ILoadingUser = {
  state: IUserStates.Loading
}

type ISignedOffUser = {
  state: IUserStates.SignedOut
}
type IErrorUser = {
  state: IUserStates.Error
}

export const buildUserLoadingState = (): ILoadingUser => ({ state: IUserStates.Loading })
export const buildUserSignedOut = (): ISignedOffUser => ({ state: IUserStates.SignedOut })
export const buildUserError = (): IErrorUser => ({ state: IUserStates.Error })

export const buildUserSignedIn = (userData: IUser): IAuthenticatedUser => ({
  state: IUserStates.SignedIn,
  data: userData,
})

export const isLoadingUser = (user: IUserUnionStates): boolean => user.state === IUserStates.Loading
export const isErrorUser = (user: IUserUnionStates): boolean => user.state === IUserStates.Error
export const isUserSignedIn = (user: IUserUnionStates): boolean =>
  user.state === IUserStates.SignedIn

export type IUserUnionStates = IAuthenticatedUser | ILoadingUser | ISignedOffUser | IErrorUser
