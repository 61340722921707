import { createTheme } from '@mui/material/styles'

export default createTheme({
  typography: {
    fontFamily: ['"Inter"', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
})
