import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../Assets/logo.png'

const Header = () => {
  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          height: '80px',
          position: 'fixed',
          width: '100vw',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#fff',
          maxWidth: '1200px',
        }}
      >
        <Box>
          <img src={logo} style={{ height: '80px' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              margin: '10px',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="outlined"
              sx={{ padding: '10px', fontWeight: 'bold', boxShadow: 'none', margin: '3px' }}
              onClick={() => {
                navigate('/?fullaccess=true')
              }}
            >
              Request full access
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Header
