import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import { useAppDispatch } from '../../Hooks/StoreHooks'
import { saveUserDetails, setUserLoading, setUserSignedOut } from '../../Store/user/userReducer'

const auth = getAuth()

const AuthService = () => {
  // console.log('Auth service activated')
  const dispatch = useAppDispatch()

  useEffect(() => {
    // console.log('auth service tracking')
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(setUserLoading())
      if (user) {
        user.getIdToken().then((accessToken) => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
          // handle token refresh
          // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onidtokenchanged
          auth.onIdTokenChanged((user) => {
            if (user) {
              // set refresh token
              user.getIdToken().then((accessToken) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
              })
              // User is signed in or token was refreshed.
            }
          })
          // token refresh handling ends
          dispatch(saveUserDetails())
        })
      } else {
        dispatch(setUserSignedOut())
      }
    })

    return () => {
      unsubscribe()
    }
  }, [dispatch])

  return <></>
}

export default AuthService
