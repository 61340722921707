import Main from "../Pages/Main";
import Page404 from "../Pages/Page404";
import SignIn from "../Pages/SignIn";
import Dashboard from "../Pages/Dashboard";

type NewType = {
  path: string;
  page: () => JSX.Element;
  isPrivate: boolean;
  showHeader?: boolean;
  fullWidth?: boolean;
};

type Route = NewType;

export const routes: Route[] = [
  {
    path: "/",
    page: SignIn,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: "/signin",
    page: SignIn,
    isPrivate: false,
    showHeader: false,
  },
  {
    path: "/dashboard",
    page: Dashboard,
    isPrivate: true,
    showHeader: false,
    fullWidth: true,
  },
  {
    path: "*",
    page: Page404,
    isPrivate: false,
  },
];
