import { Box, Button, Typography } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import Logo from '../Assets/logo.png'
import useAuth from '../Hooks/firebase/useAuth'
import { useAppSelector } from '../Hooks/StoreHooks'
import { isLoading, isLoggedIn, userHasError } from '../Store/user/userReducer'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Loading from '../Components/Loading'
import { useCurrentPath } from '../Hooks/useCurrentPath'

const SignIn = () => {
  const auth = useAuth()
  const _isLoggedIn = useAppSelector(isLoggedIn)
  const _isLoading = useAppSelector(isLoading)
  const _isError = useAppSelector(userHasError)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const routeProps = useCurrentPath()

  const redirected = searchParams.get('redirected')

  useEffect(() => {
    if (_isLoggedIn) {
      if (redirected) {
        navigate(-1)
      } else {
        navigate('/dashboard')
      }
    }
  }, [_isLoggedIn, redirected, navigate])

  if (_isLoading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1,
          background: '#a7d4f8',
          display: 'flex',
          minHeight: { md: '100vh', sm: '' },
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Logo} style={{ width: '50%' }} />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ margin: '10px' }}>
          <Typography variant="h6">Sign In</Typography>
        </Box>
        <Box sx={{ margin: '10px' }}>
          <Button
            variant="contained"
            sx={{ minWidth: '300px' }}
            onClick={() => auth.loginWithPopup()}
          >
            <GoogleIcon />
            <Box sx={{ margin: '5px', fontWeight: 'bold' }}>Sign in with Google</Box>
          </Button>
          {_isError && (
            <Box
              sx={{
                padding: '10px',
                color: 'red',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption">
                Error logging in - did you get an early access ?
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SignIn
