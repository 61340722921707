import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { routes } from '../Config/routes'
import { TNone } from '../Types/globals'
import PageContainer from './PageContainer'

const Router: React.FC<TNone> = () => {
  const renderRoutes = React.useMemo(
    () =>
      routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <PageContainer>
              <route.page />
            </PageContainer>
          }
        />
      )),
    []
  )

  return (
    <BrowserRouter>
      <Routes>{renderRoutes}</Routes>
    </BrowserRouter>
  )
}

export default Router
