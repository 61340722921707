import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loading: React.FC<any> = ({ text }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ padding: '10px' }}>
        <CircularProgress size="8rem" />
      </Box>
      <Box>{text || 'Loading..'}</Box>
    </Box>
  )
}

export default Loading
